<template>
  <div class="filters-content"
    v-bind:style="{ width: widthScroll + 'px' }"
  >
    <div class="filters-container">
      <div class="filters-scroll">
        <div class="move">
          <span
            v-for="(item, index) in items"
            :key="index"
            class="badge badge-dark"
            :class="{ badge: !item.canDelete }"
            style="text-transform: capitalize"
          >
            {{ item.name }}
            <a
              v-if="
                typeof item.canDelete === 'undefined' || item.canDelete === true
              "
              v-on:click.prevent="
                $bus.$emit('delete-filter-scroll', index, item)
              "
              href="#"
              class="remove-filter"
              title="Eliminar filtro..."
            >
              <i class="zmdi zmdi-close"></i>
            </a>
          </span>
        </div>
      </div>
    </div>

    <div class="filter-control-container">
      <div class="filter-controls">
        <a
          v-on:click.prevent="moveBack()"
          href="#"
          title="Atrás"
          class="btn btn-link filters-back"
        >
          <i class="zmdi zmdi-chevron-left"></i>
        </a>
        <a
          v-on:click.prevent="moveFordward()"
          href="#"
          title="Adelante"
          class="btn btn-link filters-fordward"
        >
          <i class="zmdi zmdi-chevron-right"></i>
        </a>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FiltersScroll",

  props: {
    items: {
      type: Array,
      default: [],
      required: true,
    },
    widthControls: {
      type: Number,
      default: 380,
      required: false,
    },
  },

  data() {
    return {
      left: 0, // Contiene la posicion actual en pixeles de .move
      width: 0, // Contiene el ancho total de la suma de todos los tags (filtros)
    };
  },

  methods: {
    moveBack() {
      this.left += 100;

      if (this.left >= 0) {
        document
          .querySelector(".filter-controls .filters-back")
          .classList.add("disabled");
      } else {
        document
          .querySelector(".filter-controls .filters-fordward")
          .classList.remove("disabled");
      }
    },

    moveFordward() {
      // Validar si llegue al final o no
      this.left -= 100;

      if (this.left < 0) {
        document
          .querySelector(".filter-controls .filters-back")
          .classList.remove("disabled");

        if (this.left <= 0 - this.width + 100) {
          document
            .querySelector(".filter-controls .filters-fordward")
            .classList.add("disabled");
        } else {
          document
            .querySelector(".filter-controls .filters-fordward")
            .classList.remove("disabled");
        }
      }
    },

    getComputedWidth(el) {
      return parseInt(el.offsetWidth);
    },
  },

  computed: {
    widthScroll() {
      return window.innerWidth - this.widthControls;
    },
  },

  // Chequeo los items para saber si habilitar o no los botones de desplazamiento
  updated() {
    // Selecciono todos los spanpaddin
    var spans = document.querySelectorAll(".filters-scroll .move span");

    // Sumo todos los width
    var width = 0;

    for (var a = 0; a < spans.length; a++) {
      width += this.getComputedWidth(spans[a]);
    }

    this.width = width;

    // Si la suma de los width es mayor al ancho de .filters-scroll
    var FiltersScroll = document.querySelector(".filters-scroll");

    if (width > this.getComputedWidth(FiltersScroll)) {
      // Habilito los botones para desplazarse
      if (this.left >= 0) {
        document
          .querySelector(".filter-controls .filters-back")
          .classList.remove("dipslay-none");
        document
          .querySelector(".filter-controls .filters-back")
          .classList.add("disabled");
      }
      document
        .querySelector(".filter-controls .filters-fordward")
        .classList.remove("dipslay-none");
    } else {
      // Muevo a cero el div
      this.left = 0;

      // Deshabilito los botones para desplazarse
      document
        .querySelector(".filter-controls .filters-back")
        .classList.add("dipslay-none");
      document
        .querySelector(".filter-controls .filters-fordward")
        .classList.add("dipslay-none");
    }
  },

  watch: {
    // Cada vez que modifico el left, muevo el div
    left: function (newValue, oldValue) {
      document.querySelector(".filters-scroll .move").style.transform =
        "translateX(" + newValue + "px)";
    },
  },
};
</script>
